import { LoadingView } from '@components/loading/LoadingView';
import ListNotificationItemView from '@components/notification/ListNotificationItemView';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuthState } from '@providers/AuthProvider';
import { Notification } from '@src/API';
import { NotificationType } from '@src/customGraphql/customModels';
import usePageBottom from '@src/hooks/usePageBottom';
import captureException from '@src/services/loggerService';
import { useListNotificationByUserIdInfiniteQuery } from '@src/services/notificationHooks';
import { INotificationService, RealNotificationService } from '@src/services/notificationService';
// import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import IconEmptyNotificationSVG from '/public/ic-empty-notification.svg';

export default function ListNotification() {
  const router = useRouter();
  const { isAuthenticated, user } = useAuthState();
  const notificationService = useMemo<INotificationService>(
    () => new RealNotificationService(),
    [],
  );

  // States
  const [listNotification, setListNotification] = useState<Notification[]>([]);
  const [screenCss, setScreenCss] = useState<string>('');
  const [shouldShowSeeMoreButton, setShouldShowSeeMoreButton] = useState<boolean>(true);
  const isScrollBottom = usePageBottom();

  // Fetch list notification
  const {
    isFetched,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    data: listNotificationByUserId,
    refetch,
  } = useListNotificationByUserIdInfiniteQuery(user?.id);

  // Effects
  useEffect(() => {
    if (router.pathname === '/notifications') {
      setScreenCss('grow-0 w-full md:w-152 mt-2 md:mt-6 bg-white p-4 md:p-3');
    } else {
      setScreenCss('grow-0 w-full md:w-50 bg-white p-4 md:p-3 max-h-152 overflow-y-auto');
    }
  }, [router]);

  useEffect(() => {
    const isNotificationPath = router.pathname === '/notifications';
    const isEmptyListNotification = listNotification.length === 0;
    if (isNotificationPath || isEmptyListNotification) {
      setShouldShowSeeMoreButton(false);
    } else {
      setShouldShowSeeMoreButton(true);
    }
  }, [router, listNotification]);

  useEffect(() => {
    const arr = listNotificationByUserId?.pages
      .map((page) => {
        if (page) {
          const items = page.list || [];
          return [...items];
        }
      })
      .flat();
    if (arr) {
      const notifications: Notification[] = [];
      arr.forEach((item) => {
        if (item) {
          notifications.push(item);
        }
      });
      setListNotification(notifications);
    }
  }, [listNotificationByUserId]);

  // UIs
  const handleSelectedNotification = async (notification: Notification | null | undefined) => {
    if (notification) {
      setListNotification((prev) => {
        const newListNotification = prev.map((item) => {
          if (item.id === notification.id) {
            return {
              ...item,
              is_read: 1,
            };
          }
          return item;
        });
        return newListNotification;
      });
      try {
        await notificationService.update({
          notificationId: notification.id,
          userId: notification.user_id,
          isRead: 1,
          publicationDateTime: notification.publication_date_time,
        });
      } catch (error) {
        captureException(error);
      }

      const notificationType = notification.notification_type;
      if (!notificationType) return;
      let url = '';
      switch (notificationType) {
        case NotificationType.CONTRACT:
          url = `/contracts/${notification.underlying_object_id}`;
          break;
        case NotificationType.CONTRACT_FOR_BUYER:
          url = `/buyer/contracts/${notification.underlying_object_id}`;
          break;
        case NotificationType.CONTRACT_FOR_SELLER:
          url = `/artist/contracts/${notification.underlying_object_id}`;
          break;
        case NotificationType.JOB_DETAIL:
          url = `/jobs/${notification.underlying_object_id}`;
          break;
        case NotificationType.PROPOSAL_DETAIL:
          url = `/proposals/${notification.underlying_object_id}`;
          break;
        case NotificationType.USER_DETAIL:
          url = `/users/${notification.underlying_object_id}`;
          break;
        case NotificationType.JOB_ACCEPT_SELLER:
          url = `/jobs/${notification.underlying_object_id}`;
          break;
      }
      if (url.length > 0) {
        router.push(url);
        refetch();
      } else {
        captureException(new Error('Notification type is not supported'));
      }
    }
  };

  const handleScrollListNotification = async (e: React.UIEvent<HTMLElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 1;
    console.log('QQ: bottom', bottom);
    if (bottom) {
      console.log('QQ: fetchNextPage 1');
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage();
    }
  };

  useEffect(() => {
    if (isScrollBottom) {
      console.log('QQ: fetchNextPage 2');
      if (isFetchingNextPage || !hasNextPage) return;
      fetchNextPage();
    }
  }, [isScrollBottom, isFetchingNextPage, hasNextPage, fetchNextPage]);

  if (!isAuthenticated) return null;

  return (
    <div className="flex">
      <div className="grow" />
      <div className={screenCss} onScroll={handleScrollListNotification}>
        <div className="flex items-center mb-3 px-3">
          <span className="text-2xl font-semibold grow">Thông báo</span>
          {shouldShowSeeMoreButton && (
            <Link href="/notifications" passHref>
              <a href="replace" className="flex flex-col items-center">
                <span className="text-primary-p4 text-s grow-0 font-semibold no-underline">
                  Xem tất cả
                </span>
              </a>
            </Link>
          )}
        </div>

        <div className="flex flex-col">
          {!isFetched && <LoadingView />}
          {isFetched && listNotification.length === 0 && (
            <div className="flex flex-col">
              <div className="flex items-center justify-center">
                <IconEmptyNotificationSVG />
                {/* <Image
                  src={'/ic-empty-notification.svg'}
                  height={160}
                  width={240}
                  alt="Empty Notification"
                /> */}
              </div>
              <span className="flex font-semibold text-content-t100 items-center justify-center text-m mt-4">
                Bạn chưa có thông báo mới
              </span>
            </div>
          )}
          {isFetched && listNotification.length > 0 && (
            <div>
              {listNotification.map((notification, index) => (
                <div key={`${index}`}>
                  <ListNotificationItemView
                    notification={notification}
                    shouldShowDelete={false}
                    onSelectedNotification={handleSelectedNotification}
                  />
                </div>
              ))}
              {hasNextPage && (
                <div className="flex items-center justify-center py-2 my-3 z-0 w-full bg-transparent">
                  <CircularProgress
                    color="primary"
                    size={30}
                    sx={{
                      color: '#1976D2',
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="grow" />
    </div>
  );
}
