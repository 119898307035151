import { CreateNotificationInput, Notification, UpdateNotificationInput } from '@src/API';
import { ListData } from '@src/libs/models';
import { INotificationRepo, RealNotificationRepo } from '@src/repositories/notificationRepo';
import captureException from './loggerService';

export interface INotificationService {
  listNotificationByUserId({
    userId,
    nextToken,
    limit,
  }: {
    userId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Notification>>;
  update({
    notificationId,
    userId,
    isRead,
    publicationDateTime,
  }: {
    notificationId: string;
    userId: string;
    isRead: number;
    publicationDateTime: string | null | undefined;
  }): Promise<Notification>;
  create(notification: CreateNotificationInput): Promise<Notification | null>;
}

export class RealNotificationService implements INotificationService {
  private notificationRepo: INotificationRepo;

  public constructor() {
    this.notificationRepo = new RealNotificationRepo();
  }

  public async listNotificationByUserId({
    userId,
    nextToken,
    limit,
  }: {
    userId: string;
    nextToken?: string;
    limit?: number;
  }): Promise<ListData<Notification>> {
    const result: ListData<Notification> = await this.notificationRepo.listNotificationsByUserId({
      userId,
      nextToken,
      limit,
    });

    return result;
  }

  public async update({
    notificationId,
    userId,
    isRead,
    publicationDateTime,
  }: {
    notificationId: string;
    userId: string;
    isRead: number;
    publicationDateTime: string | null | undefined;
  }): Promise<Notification> {
    const notificationInput: UpdateNotificationInput = {
      id: notificationId,
      is_read: isRead,
      publication_date_time: publicationDateTime,
    };

    const result: Notification = await this.notificationRepo.update(notificationInput);
    return result;
  }

  public async create(notification: CreateNotificationInput): Promise<Notification | null> {
    try {
      const result: Notification = await this.notificationRepo.create(notification);
      return result;
    } catch (error) {
      captureException(error);
      return null;
    }
  }
}
