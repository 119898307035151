import MainAvatar from '@components/profile/MainAvatar';
import { Notification } from '@src/API';
import { truncateText } from '@src/utils/formatText';
import dayjs from 'dayjs';
import Image from 'next/image';
import { useEffect, useState } from 'react';

type NotificationItemViewProps = {
  notification: Notification | null | undefined;
  shouldShowDelete: boolean;
  onSelectedNotification: (notification: Notification | null | undefined) => void;
};

export default function NotificationItemView(props: NotificationItemViewProps) {
  const { notification, onSelectedNotification } = props;

  /* States */
  const [titleCss, setTitleCss] = useState('text-s-tall text-content-t200');
  const [dateCss, setDateCss] = useState('text-s text-content-t300');

  /* Functions */
  const handleSelectedNotification = async () => {
    onSelectedNotification(notification);
  };

  /* Effects */

  // Check Unread
  useEffect(() => {
    if (notification?.is_read === 0) {
      setTitleCss('text-s-tall text-content-100 font-semibold');
      setDateCss('text-s text-primary-p4 font-semibold');
    } else {
      setTitleCss('text-s-tall text-content-t200');
      setDateCss('text-s text-content-t300');
    }
  }, [notification]);

  /* UIs */

  return (
    <button
      className="flex justify-center items-center w-full py-2 px-3 rounded-lg hover:bg-greyscale-g1"
      onClick={handleSelectedNotification}
    >
      <div className="shrink-0">
        {notification?.notification_sender_id &&
          notification?.notification_sender_id.toLowerCase() !== 'none' && (
            <MainAvatar
              sizeType="md"
              imageSrc={notification?.notification_sender?.profile_picture_url || ''}
              linkUrl={''}
              displayName={notification?.notification_sender?.display_name || ''}
              canOpenProfile={false}
              isOpenNewTab={false}
            />
          )}

        {notification?.notification_sender_id?.toLowerCase() === 'none' && (
          <Image
            alt="Dreamerly photo"
            className="rounded-full"
            layout="fixed"
            objectFit="cover"
            src="/ic-logomark.svg"
            width={56}
            height={56}
          />
        )}
      </div>
      <div className="flex flex-col w-full ml-3 text-left space-y-1">
        <span className={titleCss}>{truncateText(notification?.body, 200)}</span>
        <span className={dateCss}>
          {dayjs(new Date(notification?.publication_date_time || '')).fromNow()}
        </span>
      </div>
    </button>
  );
}
